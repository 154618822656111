import { useQuill } from 'react-quilljs';

import * as Y from 'yjs';
// @ts-ignore No types for this thing
import { WebsocketProvider } from 'y-websocket';
import { QuillBinding } from 'y-quill';
import QuillCursors from 'quill-cursors';

import 'quill/dist/quill.snow.css';
import { useEffect } from 'react';

const BASE_URL = "s://books-worker.zegevlier.workers.dev";
// const BASE_URL = "://192.168.178.28:8787";

const ydoc = new Y.Doc();
const provider = new WebsocketProvider('ws' + BASE_URL, 'zegevlier', ydoc);
const ytext = ydoc.getText('quill');


function App() {
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
      ],
      cursors: true,
      history: {
        userOnly: true,
      }
    }
  });  
  if (Quill && !quill) {
    Quill.register('modules/cursors', QuillCursors);
  }

  useEffect(() => {
    if (quill) { 
      const binding = new QuillBinding(ytext, quill, provider.awareness);
      // fetch(`http${BASE_URL}/zegevlier`).then(res => res.json()).then(data => { quill.setContents(data) });
    }
  });

  provider.awareness.setLocalStateField('user', {
    name: '',
    color: `#${Math.floor(Math.random() * 0xffffff).toString(16).padEnd(6, "0")}`
  })

  return (
    <div style={{ width: 600, height: 500 }}>
      <button onClick={async () => {
        document.location.href = `http${BASE_URL}/zegevlier/save`
      }}>Save</button>
      <div ref={quillRef} />
    </div>
  );
}

export default App;
